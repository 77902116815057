import { state } from "./state";
import { MutationTree } from "vuex";

export const mutations: MutationTree<typeof state> = {

  removeItemFromIndex(state, index: number) {
    state.fileWasConverted.splice(index, 1);
    state.uploadedFilesIds.splice(index, 1);
    state.uploadedFiles.splice(index, 1);
    state.fileWordsCounted.splice(index, 1);
    state.filePagesCounted.splice(index, 1);
    state.isLanguageDropdownVisible.splice(index, 1);
  },
  removeShowHistoryModalFromIndex(state, index: number): void {
    state.showHistoryModal.splice(index, 1);
  },
  resetFileValues(state) {
    state.availableFormats = [];
    state.selectedFormat = "";
    state.uploadedFiles = [];
    state.uploadedFilesIds = [];
    state.fileWasConverted = [];
    state.filePagesCounted = [];
    state.fileWordsCounted = [];
    state.operationIsExecuting = false;
    state.isLanguageDropdownVisible = [];
    state.areAllFilesUploaded = false;
    state.areAllFilesConverted = false;
    state.areAllFilesCounted = false;
    state.isCountOperationStarted = false;
    state.isWatermarkOperationStarted = false;
    state.showProgressBar = true;
    state.showLanguageComponent = false;
  },
  resetAvailableFormats(state): void {
    state.availableFormats = [];
  },
  resetSelectedFormat(state): void {
    state.selectedFormat = '';
  },
  resetHistoryModal(state): void {
    state.showHistoryModal = [];
  },
  toggleLanguageDropdown(state, index: number): void {
    state.isLanguageDropdownVisible[index] = !state.isLanguageDropdownVisible[index];
  },
  setSelectedFormat(state, payload: string): void {
    state.selectedFormat = payload;
  },
  setLanguageList(state, payload: string): void {
    state.languageList = payload;
  },
  setOperationIsExecuting(state, payload: boolean): void {
    state.operationIsExecuting = payload;
  },
  setAreAllFilesUploaded(state, payload: boolean): void {
    state.areAllFilesUploaded = payload;
  },
  setShowProgressBar(state, payload: boolean): void {
    state.showProgressBar = payload;
  },
  setAreAllFilesConverted(state, payload: boolean): void {
    state.areAllFilesConverted = payload;
  },
  setAreAllFilesCounted(state, payload: boolean): void {
    state.areAllFilesCounted = payload;
  },
  setIsCountOperationStarted(state, payload: boolean): void {
    state.isCountOperationStarted = payload;
  },
  setIsWatermarkOperationStarted(state, payload: boolean): void {
    state.isWatermarkOperationStarted = payload;
  },
  setShowDeleteFilePopups(state, payload: boolean): void {
    state.showDeleteFilePopups = payload;
  },
  setShowLanguageComponent(state, payload: boolean): void {
    state.showLanguageComponent = payload;
  },
  setIsDarkTheme(state, payload: boolean): void {
    state.isDarkTheme = payload;
  },
  setCurrentLocation(state, payload: string): void {
    state.currentLocation = payload;
  },
  setFilesAreDownloading(state, payload: boolean): void {
    state.filesAreDownloading = payload;
  },
  setAvailableFormats(state, payload:Array<string>): void {
    state.availableFormats = payload;
  },
  setUploadedFilesIds(state, {payload, index}): void {
    state.uploadedFilesIds[index] = payload;
  },
  setShowHistoryModal(state, {payload, index}): void {
    state.showHistoryModal[index] = payload;
  },
  setFileWasConverted(state, {payload, index}): void {
    state.fileWasConverted[index] = payload;
  },
  setFilePagesCounted(state, {payload, index}): void {
    state.filePagesCounted[index] = payload;
  },
  setFileWordsCounted(state, {payload, index}): void {
    state.fileWordsCounted[index] = payload;
  },
  setIsLanguageDropdownVisible(state, {payload, index}): void {
    state.isLanguageDropdownVisible[index] = payload;
  },
  setAllLanguageDropdownsToFalse(state): void {
    state.isLanguageDropdownVisible.fill(false);
  },
  setFileCountRetryToZero(state, {index}): void {
    state.uploadedFiles[index].pagesRetryCount = 0;
    state.uploadedFiles[index].wordsRetryCount = 0;
  },
  setUploadedFiles(state, {payload, prop, index}): void {
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
    state.uploadedFiles[index][prop] = payload;
  },
  decrementFileConvertRetryCount(state, {index}): void {
      state.uploadedFiles[index].convertRetryCount -= 1;
    },
  decrementFilePagesRetryCount(state, {index}): void {
      state.uploadedFiles[index].pagesRetryCount -= 1;
    },
  decrementFileWordsRetryCount(state, {index}): void {
      state.uploadedFiles[index].wordsRetryCount -= 1;
    },

  //  pushing values
  addUploadedFilesId(state, payload): void {
    state.uploadedFilesIds.push(payload);
  },
  addFileWasConverted(state, payload): void {
    state.fileWasConverted.push(payload);
  },
  addFilePagesCounted(state, payload): void {
    state.filePagesCounted.push(payload);
  },
  addFileWordsCounted(state, payload): void {
    state.fileWordsCounted.push(payload);
  },
  addShowHistoryModal(state, payload): void {
    state.showHistoryModal.push(payload);
  },
  addUploadedFiles(state, payload): void {
    state.uploadedFiles.push(payload);
  },
};
