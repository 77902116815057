import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import { oidcSettings } from '../components/auth/oidcSettings';
import axios from 'axios';
import store from '../store/store';
import ConvertView from '../views/ConvertView.vue';
import CountView from '../views/CountView.vue';
import WatermarkView from '../views/WatermarkView.vue';
import OidcCallback from '../components/auth/oidc-callback.vue';
import LogoutPage from '../components/auth/LogoutPage.vue';

const routes: Array<RouteRecordRaw> = [

  {
    path: '/:pathMatch(.*)*',
    redirect: '/convert'
  },
  {
    path: '/convert',
    name: 'home',
    component: ConvertView,
  },
  {
    path: '/count',
    name: 'count',
    component: CountView,
  },
  {
    path: '/watermark',
    name: 'watermark',
    component: WatermarkView,
  },
  {
    path: '/auth',
    name: 'auth',
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
  {
    path: '/silent-auth',
    name: 'silentAuth',
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
    props: { silentMode: true },
  },
  {
    path: '/logout',
    redirect: '/convert',
    component: LogoutPage,
    meta: {
      isPublic: true,
    },
  },
];
const router = createRouter({
  history: createWebHistory('/'),
  routes,
});
router.beforeEach(vuexOidcCreateRouterMiddleware(store));

axios.interceptors.request.use(
  async (configParam) => {
    const config = configParam;
    if (config.url && config.url.includes(oidcSettings.authority as string)) {
      return config;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.Authorization = config.headers.Authorization || `Bearer ${store.state.oidcStore.access_token}`;
    return config;
  },
  (error) => Promise.reject(error),
);
export default router;
