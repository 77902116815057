import { GetterTree } from 'vuex';
import { state } from './state';

export const getters: GetterTree<typeof state, typeof state> = {
  availableFormats:(state) => state.availableFormats,
  selectedFormat:(state) => state.selectedFormat,
  languageList:(state) => state.languageList,
  showLanguageComponent:(state) => state.showLanguageComponent,
  showProgressBar:(state) => state.showProgressBar,
  uploadedFiles:(state) => state.uploadedFiles,
  uploadedFilesIds:(state) => state.uploadedFilesIds,
  fileWasConverted:(state) => state.fileWasConverted,
  filePagesCounted:(state) => state.filePagesCounted,
  fileWordsCounted:(state) => state.fileWordsCounted,
  isLanguageDropdownVisible:(state) => state.isLanguageDropdownVisible,
  isCountOperationStarted:(state) => state.isCountOperationStarted,
  isWatermarkOperationStarted:(state) => state.isWatermarkOperationStarted,
  showDeleteFilePopups:(state) => state.showDeleteFilePopups,
  allSupportedFormats:(state) => state.allSupportedFormats,
  currentLocation:(state) => state.currentLocation,
  areAllFilesUploaded:(state) => state.areAllFilesUploaded,
  areAllFilesCounted:(state) => state.areAllFilesCounted,
  areAllFilesConverted:(state) => state.areAllFilesConverted,
  operationIsExecuting:(state) => state.operationIsExecuting,
  filesAreDownloading:(state) => state.filesAreDownloading,
  operationFailedRetryCount:(state) => state.operationFailedRetryCount,
  showHistoryModal:(state) => state.showHistoryModal,
  isDarkTheme:(state) => state.isDarkTheme,

  // custom getters
  areFilesUploaded:(state) => state.uploadedFiles.length > 0,
  showDownloadAllButton:(state) => state.uploadedFiles.length > 1,
  areFilesIds:(state) => state.uploadedFilesIds.length > 0,
  areAvailableFormats:(state) => state.availableFormats.length > 0,
  isFormatSelected:(state) => state.selectedFormat !== '',
  toggleHistoryModal: (state) => (index: number) => state.showHistoryModal[index] = !state.showHistoryModal[index],
}