import axios from "axios";
import store from "@/store/store";
import File from "@/interfaces/File";
import SetLanguagesApiResponse from "@/interfaces/SetLanguagesApiResponse";

export default async function setLanguages() {
  const { uploadedFiles } = store.getters;
  if (uploadedFiles.every((file: File) => file.languageCode === "en" || file.languageCode === "")) {
    return;
  }
  const filesByLanguage: Record<string, Array<{ id: string }>> = {};
  for (const file of uploadedFiles) {
    if (file.languageCode !== "en") {
      if (!filesByLanguage[file.languageCode]) {
        filesByLanguage[file.languageCode] = [];
      }
      filesByLanguage[file.languageCode].push({ id: file.id });
    }
  }
  for (const language in filesByLanguage) {
    await axios({
      method: "POST",
      url: `${process.env.VUE_APP_BASE_URL}/api/files?language=${language}&discard=true`,
      headers: {
        "Content-Type": "application/json",
      },
      data: filesByLanguage[language],
    })
      .then(response => updateFilesId(response.data))
      .catch((err) => err);
  }
}

function updateFilesId(responseData: SetLanguagesApiResponse) {
  const { uploadedFiles } = store.getters;
  for (const oldFileId in responseData) {
    for (let i = 0; i < uploadedFiles.length; i++) {
      if (uploadedFiles[i].id === oldFileId) {
        const newFileId = responseData[oldFileId][0];
        store.commit("setUploadedFiles", {
          payload: newFileId,
          prop: "id",
          index: i,
        });
        store.commit("setUploadedFilesIds", {
          payload: newFileId,
          index: i,
        });
        break;
      }
    }
  }
}