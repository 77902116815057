export const oidcSettings = {
  authority: process.env.VUE_APP_AUTHORITY,
  clientId: process.env.VUE_APP_CLIENT_ID,
  redirectUri: `${window.location.origin}/auth`,
  silent_redirect_uri: `${window.location.origin}/silent-auth`,
  post_logout_redirect_uri: `${window.location.origin}/logout`,
  responseType: 'id_token token',
  scope: 'openid email A2A profile'
} as const;

