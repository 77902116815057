// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueMatomo from 'vue-matomo';
import VueSweetalert2 from 'vue-sweetalert2';
import { createApp } from 'vue';
import App from './App.vue';
import store from './store/store';
import router from './router';
import './styles/App.scss';

createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_HOST,
    siteId: process.env.VUE_APP_MATOMO_ID,
    trackerFileName: 'matomo',
    router,
    enableLinkTracking: true,
    trackInitialView: true,
  })
  .mount('#app');
