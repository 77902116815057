import Swal from "sweetalert2";
import store from "@/store/store";

const defaultPopup = Swal.mixin({
  customClass: {
    confirmButton: "popupBlueButton",
    popup: 'toast-theme',
    title: "defaultFont",
  },
  buttonsStyling: false,
});
const defaultPopupWithOptions = Swal.mixin({
  customClass: {
    confirmButton: "popupBlueButton",
    cancelButton: "popupGreyButton",
    popup: 'toast-theme',
    title: "defaultFont",
  },
  buttonsStyling: false,
});
export const popups = {
  tooManyFilesPopup() {
    defaultPopup.fire({
      position: "center",
      icon: "info",
      title: "You can upload a maximum of 50 files",
      showConfirmButton: true,
      width: 450,
    });
  },
  unsupportedFilesPopup(unsupportedFileFormats: Array<string>) {
    const uniqueFormats = [...new Set(unsupportedFileFormats)];
    let message = `Your selection contains files with an unsupported format: \n \n ${uniqueFormats}`;
    if (uniqueFormats.length > 1) {
      message = `Your selection contains files with unsupported formats: \n \n ${uniqueFormats}`;
    }
    defaultPopup.fire({
      position: "center",
      icon: "warning",
      title: `${message}`,
      showConfirmButton: true,
      width: 500,
    });
  },
  restrictToPdfPopup() {
    defaultPopup.fire({
      position: "center",
      icon: "info",
      title: 'Watermark feature currently supports PDF files only.',
      showConfirmButton: true,
      width: 500,
    });
  },
  reuseFailPopup() {
    defaultPopup.fire({
      position: "center",
      icon: "info",
      title: "This file is already in use!",
      showConfirmButton: true,
      width: 450,
    });
  },
  duplicateFilePopup() {
    defaultPopup.fire({
      position: "center",
      icon: "info",
      title: "Potential duplicate files detected during upload!",
      showConfirmButton: true,
      width: 450,
    });
  },
  deleteFileWarningPopup(id:string) {
    const deleteConfirmed = new CustomEvent("delete-confirmed", {detail:id});
    if (store.getters.showDeleteFilePopups) {
      defaultPopupWithOptions
        .fire({
          position: "center",
          icon: "question",
          title: "Are you sure you want to delete this file?",
          showConfirmButton: true,
          confirmButtonText: "Yes",
          showCancelButton: true,
          input: "checkbox",
          inputPlaceholder: "<p class='defaultFont'>Don't show this again</p>",
          width: 450,
          inputValidator: (result): any => {
            if (result) {
              store.commit("setShowDeleteFilePopups", false);
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            document.dispatchEvent(deleteConfirmed);
          }
        });
    } else {
      document.dispatchEvent(deleteConfirmed);
    }
  },
  deleteAllFilesWarningPopup() {
    const deleteAllConfirmed = new CustomEvent("delete-all-confirmed");
    defaultPopupWithOptions
      .fire({
        position: "center",
        icon: "question",
        title: "Are you sure you want to delete all files from history?",
        showConfirmButton: true,
        confirmButtonText: "Yes",
        showCancelButton: true,
        width: 450,
      })
      .then((result) => {
        if (result.isConfirmed) {
          document.dispatchEvent(deleteAllConfirmed);
        }
      });
  },
} as const;
